import { VStack, Text, HStack } from "@yahoo/uds";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { Facebook } from "./Facebook";
import { Instagram } from "./Instagram";
import { Twitter } from "./Twitter";

interface Props {}

type SocialLinksType = {
  component?: FC<{ url: string }>;
  url: string;
}[];

const socialLinks: SocialLinksType = [
  {
    component: Twitter,
    url: "https://x.com/Yahoo",
  },
  {
    component: Facebook,
    url: "https://www.facebook.com/yahoo",
  },
  {
    component: Instagram,
    url: "https://www.instagram.com/yahoo/",
  },
];

export const SocialLinks: FC<Props> = () => {
  const intl = useIntl();
  return (
    <VStack>
      <Text
        variant="label2"
        color="primary"
        display="block"
        spacingBottom="2.5"
        className="hidden sm:block"
      >
        {intl.formatMessage({ id: "footer.SOCIAL" })}
      </Text>
      <HStack className="gap-x-4 sm:gap-x-8">
        {socialLinks.map((link, index) => {
          const { component: Component, url } = link;
          if (Component) {
            return <Component url={url} key={index} />;
          } else {
            return null;
          }
        })}
      </HStack>
    </VStack>
  );
};
