import { I13nAnchor } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import { useIntl } from "react-intl";
import TwitterLogo from "../svg/social/twitter.svg";

interface Props {
  url: string;
}

export const Twitter: FC<Props> = ({ url }) => {
  const intl = useIntl();

  return (
    <I13nAnchor
      dataYlk={{
        elm: "follow",
        itc: 0,
        outcm: "follow",
        sec: "footer",
        slk: "twitter",
      }}
      aria-label={intl.formatMessage({ id: "footer.TWITTER" })}
      href={url}
      rel="noreferrer noopener"
      target="_blank"
    >
      <TwitterLogo />
    </I13nAnchor>
  );
};
