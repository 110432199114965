"use client";

import { I13nAnchor } from "@yahoo-commerce/i13n";
import Image from "next/image";
import { type FC } from "react";
import {
  type IconPrivacyLink,
  isCompoundPrivacyLink,
  parseCompoundPrivacyLink,
  type CompoundPrivacyLink,
  type PrivacyLink as PrivacyLinkType,
  isIconPrivacyLink,
} from "../lib/privacy";

const Icon = ({ icon }: Pick<IconPrivacyLink, "icon">) => {
  return (
    <Image
      alt=""
      aria-hidden
      className="ml-px inline-block h-[13px] w-[25px]"
      height={13}
      src={icon}
      width={25}
    />
  );
};

const CompoundPrivacyLink: FC<{
  link: CompoundPrivacyLink;
  className?: string;
}> = ({ link, className }) => {
  return parseCompoundPrivacyLink(link).map((item, index) => {
    if (item.type === "link") {
      return (
        <I13nAnchor
          href={item.url}
          dataYlk={{ ylk: "sec:footer;subsec:corp" }}
          key={index}
          target="_blank"
          className={className}
        >
          {item.text}
        </I13nAnchor>
      );
    }

    return item.text;
  });
};

export const PrivacyLink: FC<{
  link: PrivacyLinkType;
  className?: string;
}> = ({ link, className }) => {
  if (isCompoundPrivacyLink(link)) {
    return <CompoundPrivacyLink link={link} className={className} />;
  }
  return (
    <I13nAnchor
      href={link.url}
      dataYlk={{
        elm: "link",
        slk: link.label,
      }}
      target="_blank"
      className={className}
    >
      {link.label}
      {isIconPrivacyLink(link) && <Icon icon={link.icon} />}
    </I13nAnchor>
  );
};
