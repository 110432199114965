"use client";

import { I13nAnchor } from "@yahoo-commerce/i13n";
import { useState, type FC } from "react";
import { type PrivacyEntry } from "../lib/privacy";
import { ConsentLibrary } from "./ConsentLibrary";
import { PrivacyLink } from "./PrivacyLink";

interface Props {
  acookieFields?: string;
  lang: string;
  partner: string;
  links?: { text: string; url: string }[];
}

const dataYlk = {
  elm: "corp",
  itc: 0,
  sec: "footer",
};

export const ArticleFooter: FC<Props> = ({
  acookieFields,
  lang,
  partner,
  links,
}) => {
  const [privacyEntries, setPrivacyEntries] = useState<PrivacyEntry[]>([]);

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="no-scrollbar col-body mx-4 mb-8 mt-2.5 overflow-x-scroll whitespace-nowrap md:mx-0 md:max-w-[750px] md:overflow-x-visible">
      <div className="pointer-events-none absolute right-3.5 h-6 w-16 bg-gradient-to-r from-transparent to-white md:hidden" />
      <div className="mx-auto max-w-screen-sm">
        <ul className="inline-flex text-xs md:text-sm">
          {privacyEntries.map(([key, link]) => (
            <li className="mr-6 text-dolphin" key={key}>
              <PrivacyLink
                link={link}
                className="text-dolphin hover:underline"
              />
            </li>
          ))}
          {links &&
            links.map((link, index) => (
              <li className="mr-6" key={index}>
                <I13nAnchor
                  dataYlk={dataYlk}
                  href={link.url}
                  className="text-dolphin hover:underline"
                >
                  {link.text}
                </I13nAnchor>
              </li>
            ))}
        </ul>
      </div>
      <ConsentLibrary
        acookieFields={acookieFields}
        lang={lang}
        onLoadPrivacyEntries={setPrivacyEntries}
        partner={partner}
      />
    </div>
  );
};

export type articleFooterDataYlk = typeof dataYlk;
