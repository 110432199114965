"use client";

import { Box, HStack, VStack, Text } from "@yahoo/uds";
import {
  getDataYlkString,
  I13nAnchor,
  type I13NSec,
  useRapid,
} from "@yahoo-commerce/i13n";
import { useState, type FC, type ReactNode } from "react";
import { useIntl } from "react-intl";
import { type PrivacyEntry } from "../lib/privacy";
import { type FooterProps } from "../types/Footer";
import { ConsentLibrary } from "./ConsentLibrary";
import { FooterLink } from "./FooterLink";
import { PrivacyLink } from "./PrivacyLink";
import { SocialLinks } from "./SocialLinks";

interface Props extends FooterProps {
  acookieFields?: string;
  lang: string;
  partner: string;
  logo: ReactNode;
  dataYlk?: {
    category: Record<string, unknown>;
    corp: Record<string, unknown>;
  };
}

export const Footer: FC<Props> = ({
  acookieFields,
  lang,
  logo,
  links,
  partner,
  dataYlk,
}) => {
  const footerSection: I13NSec = "footer";
  const intl = useIntl();
  const { mpos } = useRapid(footerSection);
  const [privacyEntries, setPrivacyEntries] = useState<PrivacyEntry[]>([]);
  const linkClassName = "text-inkwell hover:underline";

  return (
    <footer id="footer" className="bg-gray-100">
      <div
        className="mx-auto w-full max-w-[1504px] items-center justify-between p-8"
        id={footerSection}
        data-ylk={getDataYlkString({
          itc: 0,
          mpos,
          sec: footerSection,
        })}
      >
        <h2>{logo ? logo : null}</h2>
        <HStack
          className="max-w-[970px] grow flex-col sm:flex-row"
          spacingBottom="6"
        >
          <VStack spacingBottom="4" spacingEnd="6" className="grow">
            {links?.category &&
              links.category?.map((link, index) => (
                <FooterLink key={index}>
                  <I13nAnchor
                    href={link.url}
                    dataYlk={{
                      elm: "link",
                      slk: link.text,
                      ...(dataYlk?.category || {}),
                    }}
                    className={linkClassName}
                  >
                    {link.text}
                  </I13nAnchor>
                </FooterLink>
              ))}
          </VStack>
          <Box spacingBottom="6" className="sm:hidden">
            <div className="h-px w-full bg-dirty-seagull" />
          </Box>
          <VStack
            spacingBottom="6"
            className="grid grow grid-cols-2 sm:flex sm:flex-col"
          >
            {privacyEntries.map(([index, link]) => (
              <FooterLink key={index}>
                <PrivacyLink link={link} className={linkClassName} />
              </FooterLink>
            ))}
            {links?.corp &&
              links?.corp?.map((link, index) => (
                <FooterLink key={index}>
                  <I13nAnchor
                    href={link.url}
                    dataYlk={{
                      elm: "link",
                      slk: link.text,
                      ...(dataYlk?.corp || {}),
                    }}
                    target="_blank"
                    className={linkClassName}
                  >
                    {link.text}
                  </I13nAnchor>
                </FooterLink>
              ))}
          </VStack>
          <Box className="justify-center sm:grow-[3] md:justify-end">
            <SocialLinks />
          </Box>
        </HStack>
        <Text variant="caption2" color="primary">
          {intl.formatMessage(
            { id: "footer.COPYRIGHT" },
            { year: new Date().getFullYear() },
          )}
        </Text>
      </div>
      <ConsentLibrary
        acookieFields={acookieFields}
        lang={lang}
        onLoadPrivacyEntries={setPrivacyEntries}
        partner={partner}
      />
    </footer>
  );
};
