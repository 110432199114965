import { Box, Text } from "@yahoo/uds";
import { type PropsWithChildren, type FC } from "react";

export const FooterLink: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box spacingBottom="2">
      <Text
        variant="caption2"
        color="primary"
        className="!text-[13px] !leading-[20px] md:!text-[13px] md:!leading-8"
      >
        {children}
      </Text>
    </Box>
  );
};
